import React from 'react';
import AfficheCat from '../components/AfficheCat';
import Footer from '../components/Footer';
import AfficheLivraison from '../components/Modules/AfficheLivraison';
import Entete from '../components/Modules/Entete';
import NavBar from '../components/NavBar';
import SlideBar from '../components/SlideBar';
import ThirdEtapes from '../components/ThirdEtapes';
import TopBar from '../components/TopBar';
import ViewVideo from '../components/ViewVideo';

const Home = () => {
    return (
        <div>
            <TopBar/>
            <NavBar/>
            <SlideBar/>
            <AfficheLivraison/>
            <ThirdEtapes/>
            <AfficheCat/>
            <Entete entete="Découvrir le" titre="TOP 5 DU MOMENT" sousTitre="Vous recherchez les modèles les plus en vogue du moment ?
Bienvenu(e) sur le TOP 5 Du MOMENT."/>
<Entete entete="NappyLocks" titre="WEB TV" sousTitre="Suivez toutes nos dernières vidéos"/>
            <ViewVideo/>
            <Entete entete="Autres vidéos à" titre="VISUALISER" />
            <Footer/>
        </div>
    );
};

export default Home;