import React from 'react';
import { Link } from 'react-router-dom';
import whatsapp from "../assets/images/icon/whatsapp.svg"

const TopBar = () => {
    return (
        <div className='Top-bar'>
            <p className='Top-bar__text'>Bienvenu(e) sur notre site. <img src={whatsapp} alt="whatsapp"/> +225 07 08 70 45 30</p>
            <Link to='/account' className='Top-bar__account'> 
            <i className="fi fi-rr-user"></i>
                <p>Mon Compte</p>
            </Link>

        
        </div>
    );
};

export default TopBar;