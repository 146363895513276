import React from 'react';
import Slider from 'react-slick';

const SlideBar = () => {
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
      speed: 2000,
      initialSlide: 0,
      autoplay:true,
      autoplaySpeed: 3000,
      pauseOnHover: false,
    }
        const data = [
            {
              url: "https://nappylocks.com/image/slide1.jpg",
              text : "INSTITUTE NAPPYLOCKS",
              soustext : "decouvrer l'univers des locks"
            },
            {
              url: 'https://nappylocks.com/image/slide2.jpg',
              text : "UNE SENSATION EXCEPTIONNELLE",
              soustext : "decouvrer l'univers des locks"
            },
            {
              url: 'https://nappylocks.com/image/slide3.JPG',
              text : "INSTITUTE NAPPYLOCKS",
              soustext : "decouvrer l'univers des locks"
            },
            {
              url: 'https://nappylocks.com/image/slide4.JPG',
              text : "INSTITUTE NAPPYLOCKS",
              soustext : "decouvrer l'univers des locks"
            },
            {
              url: 'https://nappylocks.com/image/slide5.JPG',
              text : "ENTRETIEN CREATION EXTENTION",
              soustext : "decouvrer l'univers des locks"
            }
          ];
    return (
        <div className='slider-header'>
            
        <Slider {...settings}>
            {data.map((item) => (
            <div key={item.url} className="box-slyder">
                <img src={item.url} alt="slider" className='slider-header__img'/>
                <span className='head-txt'>{item.text}</span>
                <span className='head-soustxt'>{item.soustext}</span>
            </div>))}
        </Slider>
        </div>
    );
};

export default SlideBar;