import React from 'react';

const ViewVideo = () => {
    return (
        <div className='box-video'>
            <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/8qHIPg9785c"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        title='video nappylocks'
       className='video'></iframe>
        </div>
    );
};

export default ViewVideo;