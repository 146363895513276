import React from 'react';

const Footer = () => {
    return (
        <div className='footer'>
            <div className='footer__newsletters'>
                 <h2>TOUT SAVOIR !</h2>
                 <p>Ne manquez rien de NappyLocks en vous inscrivant à notre newsletter.</p>
                 <input type="email" name="newsletters" value="Entrer votre Email"/>
                 <input type="submit" name="newsletters" value="S'ABONNER"/>
            </div>
            <div className='barre'>
                
            </div>
            <div className='footer__arborescence'>
                    <div className='footer__arborescence__social'>
                        <img src="#" alt="logo nappy" className='logoM'/>
                        <img src="#" alt="logo facebook"/>
                        <img src="#" alt="logo instagram"/>
                        <img src="#" alt="logo youtube"/>
                    </div>
                    <div className='footer__arborescence__text'>
                        <h3>Naviger</h3>
                        <p className='lien'>Hommes</p>
                        <p className='lien'>Femmes</p>
                        <p className='lien'>Enfant</p>
                        <p className='lien'>SE CONNECTER</p>
                        <p className='lien'>S'ENREGISTRER</p>
                    </div>
                    <div className='footer__arborescence__text'>
                        <h3>POURQUOI NOUS CHOISIR ?</h3>
                        <p className='lien'>Expédition Et Livraison A La Demande</p>
                        <p className='lien'>Service En Ligne Client</p>
                        <p className='lien'>Achats Sécurisés</p>
                        <p className='lien'>Galerie</p>
                        <p className='lien'>Contacts</p>
                    </div>
                    <div className='footer__arborescence__text'>
                        <h3>INFORMATIONS</h3>
                        <p className='lien'><i class="fi fi-rs-map-marker-home"></i>  Abidjan, Cocody Riviera Palméraie</p>
                        <p className='lien'><i class="fi fi-rr-phone-call"></i>  (+225) 0586987934 - 0747614259</p>
                        <p className='lien'><i class="fi fi-rr-envelope"></i>info@nappylocks.com</p>
                    </div>
                    <div className='footer__copyright'>
                        <p> copyright 2023 ©  nappylocks tous droits reservés.</p> <p className='dev'>Site crée par Ghoussoub | Sunder-Dev</p>
                        
                    </div>
                
            </div>

        </div>
    );
};

export default Footer;