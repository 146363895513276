import React from 'react';
import creation from '../assets/prestation/creation.svg'
import extension from '../assets/prestation/EXTENSION.svg'
import entretien from '../assets/prestation/ENTRETIEN.svg'

const ThirdEtapes = () => {
    return (
        <div className='three'>

            <div className='three__box'>
                <img src={creation} alt="création"/>
                <h3>CRÉATION</h3>
                <p>Dreadlocks naturelles</p>
            </div>
            <div className='three__box'>
                <img src={extension} alt="extention"/>
                <h3>EXTENSION</h3>
                <p>Rajouts de vrais cheveux</p>
                
            </div>
            <div className='three__box'>
                <img src={entretien} alt="entretien"/>
                <h3>ENTRETIEN</h3>
                <p>Remise en forme de vos locks</p>
            </div>
                

        </div>
    );
};

export default ThirdEtapes;