import React from 'react';

const Entete = ({entete,titre,sousTitre}) => {
    return (
        <div className='box-galerie__header'>
                <p className='entete'>{entete}</p>
                <h1 className='titre'>{titre}</h1>
                <div className='minibarre'></div>
                <p className='soustitre'>{sousTitre}</p>
            </div>
    );
};

export default Entete;