import React from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import logo from "../assets/images/logo/logo.svg"
const NavBar = () => {
    const [currentUrl, setCurrentUrl] = useState(window.location.pathname);
    const handleClick = url => {
        setCurrentUrl(url);
      };
    return (
        <div>
        <div className='Nav-bar'>
            <Link to="/"><img src={logo} alt="logo nappy" className='logoL' /></Link>
            <div className='Nav-bar__btn'>
                <button type="button"><i className="fi fi-rr-search ico"></i></button>
                <button type="button"><i className="fi fi-rr-settings"></i></button>
                <button type="button"><i className="fi fi-rr-shopping-cart"></i></button>
            </div>
            <div className='barre'></div>
            <div className='Nav-bar__liens'>
                <Link to="/" className='lien'><h4 className={currentUrl === '/' ? 'active' : ''} onClick={() => handleClick('/')} >ACCUEIL</h4></Link>
                <Link to="/boutique" className='lien' ><h4 className={currentUrl === '/boutique' ? 'active' : ''} onClick={() => handleClick('/')} >BOUTIQUE</h4></Link>
                <Link to="/galeries" className='lien' ><h4 className={currentUrl === '/galeries' ? 'active' : ''} onClick={() => handleClick('/')} >GALERIE</h4></Link>
                <Link to="/about" className='lien' ><h4 className={currentUrl === '/about' ? 'active' : ''} onClick={() => handleClick('/')} >A PROPOS</h4></Link>
                <Link to="/contacts" className='lien' ><h4 className={currentUrl === '/contacts' ? 'active' : ''} onClick={() => handleClick('/')} > CONTACTS</h4></Link>
            </div>
        </div>
        <div className='Nav-bar-petit'>
            <div className='Nav-bar__btn--fixed'>
                <button type="button"><i className="fi fi-rr-search ico"></i></button>
                <button type="button"><i className="fi fi-rr-settings"></i></button>
                <button type="button"><i className="fi fi-rr-user"></i></button>
                <button type="button"><i className="fi fi-rr-shopping-cart"></i></button>
            </div>
        </div>
        </div>
    );
};

export default NavBar;