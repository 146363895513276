
import './assets/styles/App.scss';
import { Route,Routes } from 'react-router-dom';
import Home from './pages/Home';
import Boutique from './pages/Boutique';
import Products from './pages/Products';
import Contacts from './pages/Contacts';
import Galeries from './pages/Galeries';
import Account from './pages/Account';
import About from './pages/About';
import './assets/styles/App.css';
function App() {
  return (
    <div>
       <Routes>
        <Route path='/Accueil' element={<Home/>} />
        <Route path='/*' element={<Home/>} />
        <Route path='/Boutique' element={<Boutique/>} />
        <Route path='/contacts' element={<Contacts/>} />
        <Route path='/Galeries' element={<Galeries/>} />
        <Route path='/Boutique/products' element={<Products/>} />
        <Route path='/account' element={<Account/>} />
        <Route path='/about' element={<About/>} />
     </Routes>
    </div>
  );
}

export default App;
