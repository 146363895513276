import React from 'react';

const AfficheCat = () => {
    return (
        <div className='box-galerie'>
            <div className='box-galerie__header'>
                <p className='entete'>Accès rapide</p>
                <h1 className='titre'>SELON VOTRE PROFIL</h1>
                <div className='minibarre'></div>
                <p className='soustitre'>Retrouvez rapidement nos différents modèles selon votre profil.</p>
            </div>
                <div className='box-galerie__box'>
                    <div className='box-cat'>
                        <img src="https://nappylocks.com/image/homme.webp" alt="HOMME"/>
                        <div className='box-cat--titre'>
                            <p>HOMME</p>
                            <button type="button"> VOIR +</button>
                        </div>
                    </div>
                    <div className='box-cat'>
                        <img src="https://nappylocks.com/image/femmes.webp" alt="FEMME"/>
                        <div className='box-cat--titre'>
                            <p>FEMME</p>
                            <button type="button"> VOIR +</button>
                        </div>
                    </div>
                    <div className='box-cat'>
                        <img src="https://nappylocks.com/image/enfants.jpg" alt="ENFANTS"/>
                        <div className='box-cat--titre'>
                            <p>ENFANT</p>
                            <button type="button"> VOIR + </button>
                        </div>
                    </div>
                </div>
        </div>
    );
};

export default AfficheCat;