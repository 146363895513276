import React from 'react';
import NavBar from '../components/NavBar';
import TopBar from '../components/TopBar';

const Boutique = () => {
    return (
        <div>
            <TopBar/>
            <NavBar/>
        </div>
    );
};

export default Boutique;