import React from 'react';
import camion from "../../assets/images/icon/camion.svg"
import livraison from "../../assets/images/icon/24h.svg"
import carte from "../../assets/images/icon/cart.svg"

const AfficheLivraison = () => {
    return (
        <div className='box-livraison'>
            <div className='box-livraison__box'>
                <img src={camion} alt="expedition"/>
                <div className='livraison-txt'> 
                    
                <h2>Expédition</h2>
                <p>Expédition et livraison à la demande </p>
                </div>
            </div>
            <div className='box-livraison__box'>
                <img src={livraison} alt="ouverture"/>
                <div className='livraison-txt'> 
                    
                <h2>Service H24</h2>
                <p>Service client en ligne</p>
                </div>
            </div>
            <div className='box-livraison__box'>
                <img src={carte} alt="paiement"/>
                <div className='livraison-txt'>    
                <h2>paiement En Ligne</h2>
                <p>Payer en ligne directement sur notre Site</p>
                </div>
            </div>
            <div className='barre'>
                
            </div>
            
        </div>
    );
};

export default AfficheLivraison;